<template>
    <div class="add-processing-records">
        <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
        >
            <el-form-item
                label="处理日期"
                required
                prop="handleDate"
            >
                <el-date-picker
                    type="date"
                    placeholder="选择日期"
                    v-model="ruleForm.handleDate"
                    style="width: 260px"
                    value-format="yyyy-MM-dd"
                    @change="handlerDateChange"
                ></el-date-picker>
            </el-form-item>
            <el-form-item
                label="处理方法"
                prop="handleTypeCode"
            >
                <el-select
                    style="width: 260px"
                    v-model="ruleForm.handleTypeCode"
                    placeholder="请选择处理方法"
                >
                    <el-option
                        v-for="(item, index) of processingDictionaryList"
                        :key="index"
                        :label="item.name"
                        :value="item.id"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item
                label="处理结果"
                prop="handleResult"
            >
                <el-input
                    style="width: 260px"
                    type="textarea"
                    show-word-limit
                    maxlength="200"
                    v-model="ruleForm.handleResult"
                ></el-input>
            </el-form-item>
            <div style="display: flex; justify-content: flex-end">
                <el-button @click="resetForm('ruleForm')">取消</el-button>
                <el-button
                    type="primary"
                    @click="submitForm('ruleForm')"
                    >确定</el-button
                >
            </div>
        </el-form>
    </div>
</template>
<script>
import { mapState } from 'vuex';
export default {
    name: "AddProcessingRecords",
    props: {
        processingRecords: Object,
        processingDictionaryList: Array
    },
    data() {
        return {
            ruleForm: {
                schoolId: "",
                studentId: "",
                moralId: "",
                studentName: "",
                handleDate: "",
                handleTypeCode: "",
                handleTypeName: "",
                handleResult: "",
            },
            rules: {
                handleDate: [
                    {
                        required: true,
                        message: "请选择处理日期",
                        trigger: ["blur", "change"],
                    },
                ],
                handleTypeCode: [
                    {
                        required: true,
                        message: "请选择处理方式",
                        trigger: "change",
                    },
                ],
            },
        };
    },
    computed: {
        ...mapState({
            schoolId: state => state.schoolId
        })
    },
    created () {
        this.init()
    },
    methods: {
        init () {
            this.initAssignment()
        },
        initAssignment () {
            this.ruleForm.schoolId = this.schoolId
        },
        handlerDateChange () {
            let selectTime = new Date(this.ruleForm.handleDate).getTime();
            let appraisalTime = new Date(this.processingRecords.assessDate).getTime()
            let oneDayTime = 24*60*60*1000
            let appraisalYesterDay = appraisalTime - oneDayTime
            if (selectTime < appraisalYesterDay) {
                this.$message.error('处理日期应晚于考核时间')
                this.ruleForm.handleDate = '';
                return false;
            } else {
                return true
            }
        },
        resetForm() {
            this.$emit("resetForm");
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid && this.handlerDateChange()) {
                    this.ruleForm.moralId = this.processingRecords.id;
                    this.ruleForm.studentId = this.processingRecords.studentId;
                    this.ruleForm.studentName = this.processingRecords.studentName;
                    this.ruleForm.handleTypeName = this.processingDictionaryList.filter(item => item.id == this.ruleForm.handleTypeCode)[0].name;
                    this.$emit('submitForm', this.ruleForm)
                } else {
                    return false;
                }
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.add-processing-records {
}
</style>
