<template>
    <div class="moral-education-assessment-records">
        <div id="pdfPreviewDom">
            <div class="top_row">
                <div class="score_img">
                    <img
                        :src="exportUrl"
                        alt=""
                        style="width: 14px; height: 14px"
                    />
                </div>
                <div class="all_score">总计：</div>
                <div :class="stuTotal < 0 ? 'add_score' : 'reduce_score'">
                    {{ stuTotal }}
                </div>
            </div>
            <el-table
                ref="popUpTable"
                height="400"
                :data="dialogTableList"
                v-loading="stuLoadingTable"
                border
                style="width: 100%"
                :header-cell-style="{
                    background: '#FAFBFC',
                }"
                :row-style="{height: '70px'}"
            >
                <el-table-column
                    prop="behaviorName"
                    label="考核行为"
                    width="120"
                    show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column
                    prop="score"
                    label="分数"
                    align="left"
                >
                    <template slot-scope="{row}">
                        <span :class="row.ruleType === '1' ? 'add' : 'jian'">{{
                            (row.ruleType === "1" ? "-" : "+") + row.score
                        }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="img"
                    label="凭证"
                >
                    <template slot-scope="{row}">
                        <div v-for="(item,index) in row.img" :key="index">
                            <video
                                v-if="(item.toLowerCase().indexOf('mp4') > -1 || item.toLowerCase().indexOf('mov') > -1)"
                                :class="item ? 'img2' : ''"
                                disablePictureInPicture
                                @click="handlePreview(item)"
                            >
                                <source :src="item">
                            </video>
                            <img
                                v-else
                                :src="item"
                                alt=""
                                :class="item ? 'img2' : ''"
                                @click="handleImg(item)"
                            />
                            <!-- <span v-else>-</span> -->
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="remark"
                    label="备注"
                    width="120"
                    show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column
                    v-if="moralmanageClassManageStuDataCheckPerson"
                    prop="createByName"
                    label="检查人"
                >
                </el-table-column>
                <el-table-column
                    prop="assessDate"
                    label="考核时间"
                    width="150"
                >
                </el-table-column>
                <el-table-column
                    prop="processingResults"
                    label="处理结果"
                    align="center"
                >
                    <template slot-scope="props">
                        <div
                            class="results"
                            v-if="props.row.haveHandle != 0"
                            @click="toogleExpand(props.row)"
                        >
                            {{
                                props.row.processingResultsType == "0"
                                    ? "查看"
                                    : "收起"
                            }}
                            <i
                                :class="
                                    props.row.processingResultsType == '0'
                                        ? 'el-icon-arrow-down'
                                        : 'el-icon-arrow-up'
                                "
                            ></i>
                        </div>
                        <div
                            v-else
                            class="no-results"
                        >
                            无
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    label="操作"
                    width="160"
                    align="center"
                >
                    <template slot-scope="{row}">
                        <xk-button
                            type="text"
                            class="btn_option"
                            @click="handleProcessing(row)"
                            v-hasPermi="[
                                'moralmanage:classmanage:studata:addHandle',
                            ]"
                        >
                            处理
                        </xk-button>
                        <xk-button
                            type="text"
                            class="btn_option"
                            @click="handleEdit(row)"
                            v-hasPermi="[
                                'moralmanage:classmanage:studata:editHandle',
                            ]"
                        >
                            编辑
                        </xk-button>
                        <xk-button
                            type="text"
                            class="btn_delet"
                            @click="handleDelete(row)"
                            v-hasPermi="[
                                'moralmanage:classmanage:studata:delete',
                            ]"
                        >
                            删除
                        </xk-button>
                    </template>
                </el-table-column>
                <el-table-column
                    type="expand"
                    width="1"
                >
                    <template slot-scope="props">
                        <div v-if="!props.row.processingLoading">
                            <div
                                class="processing-warper"
                                v-if="props.row.processingList.length > 0"
                            >
                                <div
                                    class="processing-list"
                                    v-for="(item, index) in props.row
                                        .processingList"
                                    :key="index"
                                >
                                    <div class="processing-title">
                                        <p class="processing-des">
                                            {{ item.createByName }}
                                            {{ item.handleTypeName }}
                                            {{ item.createTime?.slice(0, 16) }}
                                        </p>
                                        <span
                                            v-if="userId == item.createBy"
                                            class="processing-del"
                                            @click="
                                                deleteProcessing(
                                                    props.row,
                                                    item,
                                                )
                                            "
                                            >删除</span
                                        >
                                    </div>
                                    <div
                                        class="processing-info"
                                        v-if="item.handleResult"
                                    >
                                        {{ item.handleResult }}
                                    </div>
                                </div>
                            </div>
                            <div
                                v-else
                                class="empty"
                            >
                                暂无数据~
                            </div>
                        </div>
                        <div
                            v-else
                            v-loading="true"
                        ></div>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div style="text-align: right; margin-top: 20px">
            <xk-button @click="handleClose">取消</xk-button>
            <xk-button
                @click="exportStu"
                v-has-permi="['moralmanage:classmanage:studata:export']"
                type="primary"
                >下载详情</xk-button
            >
        </div>
        <!-- 宿舍的添加考核弹窗 -->
            <dialog-wrapper
                    :dialog-obj="dialogObjDormitory"
                    @handleClose="handleCloseDormitory"
                    class="simple-dialog-form"
                >
                    <div class="common-form">
                        <el-form
                            ref="formRef"
                            :model="ruleFormDormitory"
                            label-width="120px"
                            label-position="right"
                            :rules="rulesDormitory"
                        >
                            <el-form-item
                                label="考核日期"
                                prop="assessDate"
                            >
                                <el-date-picker
                                    v-model="ruleFormDormitory.assessDate"
                                    type="datetime"
                                    format="yyyy-MM-dd HH:mm"
                                    value-format="yyyy-MM-dd HH:mm"
                                    clearable
                                    placeholder="请选择考核日期"
                                    class="input-width-medium"
                                />
                            </el-form-item>
                            <el-form-item
                                label="考核类型"
                                prop="ruleType"
                            >
                                <el-radio
                                    v-model="ruleFormDormitory.ruleType"
                                    label="1"
                                    @change="changeDormiType"
                                >
                                    违纪
                                </el-radio>
                                <el-radio
                                    v-model="ruleFormDormitory.ruleType"
                                    label="2"
                                    @change="changeDormiType"
                                >
                                    荣誉
                                </el-radio>
                            </el-form-item>
                            <el-form-item
                                label="考核指标"
                                prop="ruleId"
                            >
                                <el-select-tree
                                    v-model="ruleFormDormitory.ruleId"
                                    class="input-width-medium"
                                    :data="
                                        treeFilter(
                                            treeOptions,
                                            'ruleType',
                                            ruleFormDormitory.ruleType,
                                        )
                                    "
                                    :props="{
                                        value: 'id',
                                        label: 'ruleName',
                                        children: 'children',
                                    }"
                                    clearable
                                    filterable
                                    @change="changeRule"
                                >
                                </el-select-tree>
                            </el-form-item>
                            <el-form-item
                                label="考核行为"
                                prop="behaviorId"
                            >
                                <el-select
                                    v-model="ruleFormDormitory.behaviorId"
                                    :popper-append-to-body="false"
                                    clearable
                                    filterable
                                    :title="' '"
                                    @change="changeBehavior"
                                    class="input-width-medium behavior-select"
                                >
                                    <el-option
                                        v-for="(item, index) in behaviorList"
                                        :label="item.ruleName"
                                        :value="item.id"
                                        :key="index"
                                        :title="item.ruleName"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item
                                label="上传凭证"
                                prop="img"
                            >
                                <div style="display: flex;">
                                    <el-upload
                                        :action="action"
                                        :headers="headers"
                                        :multiple="false"
                                        :file-list="fileList"
                                        :on-success="handleSuccess"
                                        :before-upload="beforeUpload"
                                        :accept="acceptValue"
                                        :show-file-list="false"
                                        :limit="3"
                                        :data="{
                                            schoolId: schoolId,
                                            mode: 'moral',
                                        }"
                                        :on-exceed="handleExceed"
                                        class="avatar-uploader"
                                    >
                                        <div
                                            v-if="isUpdataImg && isUpdataVideo"
                                            class="upload-wrap"
                                        >
                                            <div class="upload-btn">
                                                <div class="add-icon">+</div>
                                                <div class="upload-text">
                                                    上传
                                                </div>
                                            </div>
                                        </div>
                                    </el-upload>
                                    <div
                                        v-if="ruleFormDormitory.img.length > 0"
                                        class="swiper-wrap"
                                    >
                                        <div
                                            v-for="(
                                                item, index
                                            ) in ruleFormDormitory.img"
                                            :key="index"
                                            class="img-wrap"
                                        >
                                            <div class="img_each">
                                                <video
                                                    v-if="(item.toLowerCase().indexOf('mp4') > -1 || item.toLowerCase().indexOf('mov') > -1)"
                                                    style="width: 80px;height: 80px;border: 1px solid #c0ccda;"
                                                    disablePictureInPicture
                                                    >
                                                    <source :src="item">
                                                </video>
                                                <img
                                                    v-else
                                                    style="
                                                        width: 80px;
                                                        height: 80px;
                                                        border: 1px solid #c0ccda;
                                                    "
                                                    :src="item"
                                                    alt=""
                                                />
                                                <div class="del">
                                                    <i
                                                        class="el-icon-delete"
                                                        @click.stop="
                                                            deleteSwiperImg(index)
                                                        "
                                                    ></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <span class="tipText">支持上传视频和照片，视频要求短于20s，小于100M。</span>
                            </el-form-item>

                            <el-form-item
                                :label="
                                    ruleFormDormitory.ruleType === '1'
                                        ? '扣分分值'
                                        : '加分分值'
                                "
                                prop="score"
                            >
                                <el-input
                                    class="input-width-medium"
                                    v-model="ruleFormDormitory.score"
                                    readonly
                                ></el-input>
                            </el-form-item>
                            <el-form-item
                                label="考核说明"
                                prop="remark"
                            >
                                <el-input
                                    v-model="ruleFormDormitory.remark"
                                    clearable
                                    rows="5"
                                    type="textarea"
                                    maxlength="100"
                                    show-word-limit
                                    placeholder="请输入考核说明"
                                    class="input-width-medium"
                                ></el-input>
                            </el-form-item>
                        </el-form>
                        <div class="footer-button-group">
                            <xk-button @click="handleCloseDormitory">取消</xk-button>
                            <xk-button
                                type="primary"
                                @click="handleSave"
                            >
                                确定
                            </xk-button>
                        </div>
                    </div>
                </dialog-wrapper>
                <!-- 德育考核弹窗 -->
                <dialog-wrapper
                    :dialog-obj="dialogObjEduMoral"
                    @handleClose="handleCloseEduMoral"
                    class="simple-dialog-form"
                >
                    <div class="common-form">
                        <el-form
                            ref="formRefEduMoral"
                            :model="ruleFormEduMoral"
                            label-width="120px"
                            label-position="right"
                            :rules="rulesEduMoral"
                        >
                            <el-form-item
                                label="考核日期"
                                prop="assessDate"
                            >
                                <el-date-picker
                                    v-model="ruleFormEduMoral.assessDate"
                                    type="datetime"
                                    format="yyyy-MM-dd HH:mm"
                                    value-format="yyyy-MM-dd HH:mm"
                                    clearable
                                    placeholder="请选择考核日期"
                                    class="input-width-medium"
                                />
                            </el-form-item>
                            <el-form-item
                                label="考核指标"
                                prop="ruleId"
                            >
                                <el-select-tree
                                    v-model="ruleFormEduMoral.ruleId"
                                    :data="ruleListEduMoral"
                                    :props="{
                                        value: 'id',
                                        label: 'ruleName',
                                        children: 'children',
                                    }"
                                    clearable
                                    filterable
                                    @change="changeRuleEduMoral"
                                    class="input-width-medium"
                                >
                                </el-select-tree>
                            </el-form-item>
                            <el-form-item
                                label="考核行为"
                                prop="behaviorId"
                            >
                                <el-select
                                    v-model="ruleFormEduMoral.behaviorId"
                                    :popper-append-to-body="false"
                                    clearable
                                    filterable
                                     :title="' '"
                                    @change="changeBehaviorEduMoral"
                                    class="input-width-medium behavior-select"
                                >
                                    <el-option
                                        v-for="(
                                            item, index
                                        ) in behaviorListEduMoral"
                                        :label="item.ruleName"
                                        :value="item.id"
                                        :key="index"
                                        :title="item.ruleName"
                                    ></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item
                                label="上传凭证"
                                prop="img"
                            >
                            <div style="display: flex;">
                                <el-upload
                                    :action="action"
                                    :headers="headers"
                                    :multiple="false"
                                    :file-list="fileList1"
                                    :on-success="handleSuccessEduMoral"
                                    :before-upload="beforeUploadEduMoral"
                                    :accept="acceptValues"
                                    :show-file-list="false"
                                    :limit="3"
                                    :data="{
                                        schoolId: schoolId,
                                        mode: 'moral',
                                    }"
                                    :on-exceed="handleExceed"
                                    class="avatar-uploader"
                                >
                                    <div
                                        v-if="isUpdataImgs && isUpdataVideos"
                                        class="upload-wrap"
                                    >
                                        <div class="upload-btn">
                                            <div class="add-icon">+</div>
                                            <div class="upload-text">
                                                上传
                                            </div>
                                        </div>
                                    </div>
                                </el-upload>
                                <div
                                    v-if="ruleFormEduMoral.img.length > 0"
                                    class="swiper-wrap"
                                >
                                    <div
                                        v-for="(
                                            item, index
                                        ) in ruleFormEduMoral.img"
                                        :key="index"
                                        class="img-wrap"
                                    >
                                        <div class="img_each">
                                            <video
                                                v-if="(item.toLowerCase().indexOf('mp4') > -1 || item.toLowerCase().indexOf('mov') > -1)"
                                                style="width: 80px;height: 80px;border: 1px solid #c0ccda;"
                                                disablePictureInPicture
                                                >
                                                <source :src="item">
                                            </video>
                                            <img
                                                v-else
                                                style="
                                                    width: 80px;
                                                    height: 80px;
                                                    border: 1px solid #c0ccda;
                                                "
                                                :src="item"
                                                alt=""
                                            />
                                            <div class="del">
                                                <i
                                                    class="el-icon-delete"
                                                    @click.stop="
                                                        deleteSwiperImgEduMoral(
                                                            index,
                                                        )
                                                    "
                                                ></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <span class="tipText">支持上传视频和照片，视频要求短于20s，小于100M。</span>
                            </el-form-item>
                            <el-form-item
                                :label="
                                    ruleFormEduMoral.ruleType === '1'
                                        ? '扣分分值'
                                        : '加分分值'
                                "
                                prop="score"
                            >
                                <el-input
                                    class="input-width-medium"
                                    v-model="ruleFormEduMoral.score"
                                    readonly
                                ></el-input>
                            </el-form-item>
                            <el-form-item
                                label="考核说明"
                                prop="remark"
                            >
                                <el-input
                                    v-model="ruleFormEduMoral.remark"
                                    clearable
                                    rows="5"
                                    type="textarea"
                                    maxlength="100"
                                    show-word-limit
                                    placeholder="请输入考核说明"
                                    class="input-width-medium"
                                ></el-input>
                            </el-form-item>
                        </el-form>
                        <div class="footer-button-group">
                            <xk-button @click="handleCloseEduMoral"
                                >取消</xk-button
                            >
                            <xk-button
                                type="primary"
                                @click="handleSaveEduMoral"
                            >
                                确定
                            </xk-button>
                        </div>
                    </div>
                </dialog-wrapper>
                 <!-- mov视频预览 -->
                <dialog-wrapper
                    :dialog-obj="dialogMov"
                    @handleClose="handleCloseVideo"
                    class="dialog-form-video"
                >
                    <video v-if="videoMovSrc" ref="videoElement" autoplay style="width: 100%;height: 100%;" controls disablePictureInPicture>
                        <source :src="videoMovSrc">
                    </video>
                </dialog-wrapper>
    </div>
</template>
<script>
import {mapState} from "vuex";
import {
    // 业务组件
    DialogWrapper,
} from "common-local";
import {hasPermission, formatTreeData, treeFind} from "@/libs/utils";
import {SManagerEduDeormitoryDataModule} from "@/models/SManagerEduDormitoryAssessmentData.js";
import {getToken} from "@/libs/auth";
import ElSelectTree from "el-select-tree";

export default {
    name: "MoralEducationAssessmentRecords",
    props: {
        stuTotal: String,
        dialogTableList: Array,
        stuLoadingTable: Boolean,
        moralmanageClassManageStuDataCheckPerson: Boolean,
        objStudentId:Object
    },
    components: {
        DialogWrapper,
        // 功能组件
        ElSelectTree,
    },
    data() {
        return {
            hasPermission,
            dialogObjDormitory: {
                title: "编辑考核",
                width: "auto",
                dialogVisible: false,
            },
            dialogMov: {
                title: "视频预览",
                dialogVisible: false,
                width: "520px",
            },
            videoMovSrc: "",
            //宿舍考核相关
            isUpdataImg: true,
            isUpdataVideo: true,
            acceptValue:"image/*,video/mp4", //允许上传的类型
            acceptType:"", //记录上传的类型
            //德育考核、班级考核相关
            isUpdataImgs: true,
            isUpdataVideos:true,
            acceptValues:"image/*,video/mp4", //允许上传的类型
            acceptTypes:"", //记录上传的类型
            ruleFormDormitory: {
                schoolId: "",
                assessDate: "",
                ruleId: "",
                behaviorId: "",
                img: [],
                score: "",
                remark: "",
                ruleType: "1",
                type: "", // 考核范围
                stuIds: [],
            },
            dormitoryForm: {
                id:"",
                dormitoryId: "", // 楼栋id
                dormitoryName: "", // 楼栋名
                dormitoryFloorId: "", // 楼层id
                dormitoryFloorName: "", // 楼层名
                dormitoryRoomId: "", // 房间id
                dormitoryRoomName: "", // 房间名
            },
            rulesDormitory: {
                assessDate: [
                    {
                        required: true,
                        trigger: "change",
                        message: "请选择考核日期",
                    },
                ],
                ruleType: [
                    {
                        required: true,
                        trigger: "change",
                        message: "请选择考核类型",
                    },
                ],
                ruleId: [
                    {
                        required: true,
                        trigger: "change",
                        message: "请选择考核指标",
                    },
                ],
                behaviorId: [
                    {
                        required: true,
                        trigger: "change",
                        message: "请选择考核行为",
                    },
                ],
                score: [
                    {
                        pattern: /^(\d+\.\d{1,1}|\d+)$/,
                        message: "分值最多支持一位小数点",
                    },
                ],
            },
            ruleList: [],
            behaviorList: [], // 考核行为数据
            fileList: [],
            fileList1: [],
            treeOptions: [], // 指标列表
            // 德育考核弹窗
            dialogObjEduMoral: {
                title: "编辑考核",
                width: "668px",
                dialogVisible: false,
            },
            ruleFormEduMoral: {
                schoolId: "",
                classId: "",
                gradeId: "",
                onlyCode: "",
                studentId: "",
                assessDate: "",
                ruleId: "",
                behaviorId: "",
                img: [],
                score: "",
                remark: "",
                ruleType: "",
            },
            rulesEduMoral: {
                assessDate: [
                    {
                        required: true,
                        trigger: "change",
                        message: "请选择考核日期",
                    },
                ],
                ruleId: [
                    {
                        required: true,
                        trigger: "change",
                        message: "请选择考核指标",
                    },
                ],
                behaviorId: [
                    {
                        required: true,
                        trigger: "change",
                        message: "请选择考核行为",
                    },
                ],
                score: [
                    {
                        pattern: /^(\d+\.\d{1,1}|\d+)$/,
                        message: "分值最多支持一位小数点",
                    },
                ],
            },
            ruleListEduMoral: [],
            behaviorListEduMoral: [],
            stuId:{
                id:""
            },
            updateId: "",
            updatObj:{}
        }
    },
    computed: {
        ...mapState({
            userName: (state) => state.userName,
            userId: state => state.userId,
            schoolId: (state) => state.schoolId,
        }),
        exportUrl() {
            return require("@/assets/images/moralAsstessment/summary-icon.png");
        },
        action() {
            return "/api/school/common/uploadFile";
        },
        headers() {
            return {
                Authorization: getToken(),
            };
        },
    },
    methods: {
        handleImg(data) {
            this.$emit("handleImg", data);
        },
        handleCloseVideo () {
            this.$refs.videoElement.pause();
            this.videoMovSrc = "";
            this.dialogMov.dialogVisible = false;
        },
        /**
         * @Description: 预览视频
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2024-03-04 15:55:59
         */
         handlePreview(data){
            console.log("预览视频",data);
            if (data.toLowerCase().indexOf('mov') > -1) {
                this.videoMovSrc = data;
                this.dialogMov.dialogVisible = true;
            } else {
                let videoType = ['swf', 'avi', 'flv', 'mpg', 'rm', 'mov', 'wav', 'asf', '3gp', 'mkv', 'rmvb', 'mp4']
                let url = data.split('/');
                url = url[url.length -1].split('.');
                url = url[url.length -1];

                if (data.indexOf('minio') !== -1 && videoType.indexOf(url) == -1) {
                    let checkFilePreview = filePreview();
                    checkFilePreview.openNewPage(data)
                } else {
                    let url = decodeURIComponent(data) // 要预览视频文件的访问地址
                    let Base64 = require("js-base64").Base64
                    let previewUrl = `http://124.71.235.241:8012/onlinePreview?url=${encodeURIComponent(Base64.encode(url))}`
                    window.open(previewUrl)
                }
            }

		},
        toogleExpand(row) {
            let processingResultsType = row.processingResultsType === 1 ? 0 : 1;
            this.$set(row, "processingResultsType", processingResultsType);

            let popUpTable = this.$refs.popUpTable;
            popUpTable.toggleRowExpansion(row);
            this.$emit("toogleExpand", row);
        },
        handleDelete(row) {
            this.$emit("handleDelete", row);
        },
        handleClose() {
            this.$emit("handleClose");
        },
        exportStu() {
            this.$emit("exportStu");
        },
        handleProcessing(row) {
            console.log(row, "row");
            this.$emit("handleProcessing", row);
        },
        handleEdit(data1){
            let data = this._.cloneDeep(data1)
            console.log("data", data);
            this.stuId.id = data.id
            this.updatObj = this.objStudentId
            // 宿舍考核弹窗
            if (data.category == "1") {
                if (data.img.length > 0 && (data.img[0].toLowerCase().indexOf('mp4') > -1 || data.img[0].toLowerCase().indexOf('mov') > -1)) {
                    this.isUpdataVideo = false
                } else if(data.img.length === 3) {
                    this.isUpdataImg = false
                }
                console.log("11111111111111111编辑",this.isUpdataImg,this.isUpdataVideo,data.img);
                Object.keys(this.ruleFormDormitory).forEach((key) => {
                    this.ruleFormDormitory[key] = data[key];
                    this.ruleFormDormitory.category = "1";
                });
                let studArr = []
                studArr.push(data.studentId)
                this.ruleFormDormitory.stuIds = studArr;
                this.dormitoryForm.id = data.id;
                this.dormitoryForm.dormitoryId = data.dormitoryId;
                this.dormitoryForm.dormitoryName = data.dormitoryName;
                this.dormitoryForm.dormitoryFloorId = data.dormitoryFloorId;
                this.dormitoryForm.dormitoryFloorName = data.dormitoryFloorName;
                this.dormitoryForm.dormitoryRoomId = data.dormitoryRoomId;
                this.dormitoryForm.dormitoryRoomName = data.dormitoryRoomName;
                this.ruleFormDormitory.ruleId = data.ruleId
                this.getRuleList(this.ruleFormDormitory.ruleId,data.behaviorId);
                this.dialogObjDormitory.dialogVisible = true;
            } else {
                // 德育考核弹窗
                if(data.img.length > 0 && (data.img[0].toLowerCase().indexOf('mp4') > -1 || data.img[0].toLowerCase().indexOf('mov') > -1)){
                    this.isUpdataVideos = false
                }else if(data.img.length === 3) {
                    this.isUpdataImgs = false
                }
                console.log("11111111111111111编辑",this.isUpdataImg,this.isUpdataVideo,data.img);
                const keyList = [
                    "studentId",
                    "gradeId",
                    "classId",
                    "onlyCode",
                    "ruleId",
                    "behaviorId",
                    "score",
                    "remark",
                    "ruleType",
                    'assessDate',
                ];
                keyList.forEach((item) => {
                    this.ruleFormEduMoral[item] = data[item];
                });
                this.updateId = data.id
                this.ruleFormEduMoral.studentId = data.studentId;
                this.ruleFormEduMoral.schoolId = this.schoolId;
                this.ruleFormEduMoral.img = data.img
                console.log('this.ruleFormEduMoral.ruleId',this.ruleFormEduMoral.ruleId,this.ruleFormEduMoral.behaviorId);
                this.getEduMoralRuleList(this.ruleFormEduMoral.ruleId,data.behaviorId);
                this.dialogObjEduMoral.dialogVisible = true;
            }
        },
        treeFilter(data, findKey, findVal, subarrKey) {
            if (findVal) {
                let newData = data.filter((x) => x[findKey] === findVal);
                newData.forEach(
                    (x) =>
                        x[subarrKey] &&
                        (x[subarrKey] = this.treeFilter(
                            x[subarrKey],
                            findKey,
                            findVal,
                            subarrKey,
                        )),
                );
                return newData;
            } else {
                return data;
            }
        },
        /**
         * @Description: 获取宿舍考核指标列表
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-07-26 14:28:55
         */
        getRuleList(data,itedata) {
            const sManagerEduDeormitoryDataModule =
                new SManagerEduDeormitoryDataModule();
            sManagerEduDeormitoryDataModule
                .dormitoryRoomRule({
                    schoolId: this.schoolId,
                    category: "1",
                })
                .then((res) => {
                    if (res.data.code === "200") {
                        console.log(
                            "宿舍考核指标列表res.data.data",
                            res.data.data,
                        );
                        this.ruleList = formatTreeData(
                            res.data.data,
                            "id",
                            "parentId",
                        );
                        this.treeOptions = this.ruleList
                        this.ruleFormDormitory.ruleId = (res.data.data.find((i) => i.id === data))?(res.data.data.find((i) => i.id === data).id):"";
                        this.$nextTick(() => {
                            this.$refs.formRef.clearValidate();
                        });
                        console.log('this.ruleFormDormitory.ruleId222222222222222',this.ruleFormDormitory.ruleId);
                        this.changeRule(data,itedata);
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                });
        },
        changeDormiType(e){
            console.log('宿舍的eeeeeee',e);
            this.ruleFormDormitory.ruleId = "";
            this.ruleFormDormitory.behaviorId = "";
			this.ruleFormDormitory.score = "";
			this.ruleFormDormitory.ruleType = e;
        },
        /**
         * @Description: 选择考核指标，获取考核行为数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-07-25 14:20:04
         * @param {*} data
         */
        changeRule(data,itedata) {
            this.behaviorList = [];
            // this.ruleFormDormitory.ruleType = "";
            this.ruleFormDormitory.behaviorId = "";
            this.ruleFormDormitory.score = "";
            const current = treeFind(this.ruleList, (x) => x.id === data);
            if (current) {
                this.ruleFormDormitory.ruleType = current.ruleType;
                this.behaviorList = current.behaviorList;
                this.ruleFormDormitory.behaviorId = (this.behaviorList.find((i) => i.id === itedata))?(this.behaviorList.find((i) => i.id === itedata).id):'';
                this.ruleFormDormitory.score = (this.behaviorList.find((i) => i.id === itedata))?(this.behaviorList.find((i) => i.id === itedata).score):"";
            }
        },
        changeBehavior(data) {
            let find = this.behaviorList.find((i) => i.id === data);
            if (find) {
                this.ruleFormDormitory.score = find.score;
            } else {
                this.ruleFormDormitory.score = "";
            }
        },
        /**
         * handleExceed  当上传的文件超出限制时
         * beforeUpload  上传校验
         */
        //限制传文件个数
        handleExceed() {
            if (this.acceptType == "image"){
                this.$message.warning("最多选择 3 张图片");
            } else if (this.acceptType == "video") {
                this.$message.warning("最多选择 1 个视频");
            } else {
                this.$message.warning("最多选择 3 张图片 或 1 个视频");
            }
        },
        deleteSwiperImg(index) {
            this.fileList.splice(index, 1)
            this.ruleFormDormitory.img.splice(index, 1)
            console.log("打印删除后的list",this.ruleFormDormitory.img.length, this.acceptType)
            //如果第一个是图片，后面只能选图片
            if (this.ruleFormDormitory.img.length > 1 || (this.ruleFormDormitory.img.length === 1 &&  this.acceptType === 'image')) {
                this.acceptType = "image"
                this.acceptValue = "image/*"
            } else {
                this.acceptValue = "image/*,video/mp4"
                this.acceptType = ""
            }

            if(this.ruleFormDormitory.img.length<3){
                this.isUpdataImg = true
            }
            if (this.ruleFormDormitory.img.length < 1){
                this.isUpdataVideo = true
            }
        },

        /**
         * @Description: 上传校验
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-07-25 14:20:26
         * @param {*} file
         */
        beforeUpload(file) {
            console.log("file上传文件的类型", file)
            if(this.ruleFormDormitory.img.length >=1 && this.acceptType ==='image'){
                console.log('进来了吗');
                if (file.type === "video/mp4") {
                    this.$message.error("只允许上传图片!");
                    this.acceptType ==='image'
                    return false;
                }
            }
            //如果上传的是图片格式-限制上传3张
            const isImage = file.type.split("/")[0] === "image";
            const isLt20M = file.size / 1024 / 1024 < 20;
            const is50 = file.name.length < 50;
            //如果上传的是视频格式-限制上传1个视频大小在100M以内
            const isVideo = file.type === "video/mp4";
            const isLt100M = file.size / 1024 / 1024 < 100;
            if (isImage) {
                this.acceptType = "image"
                this.acceptValue = "image/*"
                if (!is50) {
                    this.$message.error("上传图片名称不能超过50个字符!");
                    return false;
                }
                if (!isLt20M) {
                    this.$message.error("上传图片大小不能超过 20MB!");
                    return false;
                }
            } else if (isVideo) {
                this.acceptType = "video"
                this.acceptValue = "video/mp4"
                if (!isLt100M) {
                    this.$message.error("视频超过系统限制请编辑后上传");
                    this.acceptValue = "image/*,video/mp4"
                    return false;
                }
               // 校验上传视频的时长不能超过20S
                return new Promise((resolve, reject) => {
                let video = document.createElement('video');
                video.preload = 'metadata';
                let _this = this;
                video.onloadedmetadata = function() {
                    window.URL.revokeObjectURL(video.src);
                    let duration = video.duration;
                    //alert('视频时长为：' + duration + '秒');
                    if (duration > 20) {
                        _this.$message.error('视频超过系统限制请编辑后上传');
                        _this.acceptValue = "image/*,video/mp4"
                        reject('视频超过系统限制请编辑后上传');
                    } else {
                        resolve(true);
                    }
                }
                video.onerror = function() {
                    reject('无法获取视频时长');
                }
                video.src = URL.createObjectURL(file);
                });
            } else if(this.ruleFormDormitory.img.length >=1) {
                this.acceptType = "image"
                this.acceptValue = "image/*"
                this.$message.error("只允许上传图片!");
                return false;
            }else {
                this.acceptType = ""
                this.acceptValue = "image/*,video/mp4"
                this.$message.error("只允许上传图片 或 视频!");
                return false;
            }
           console.log("上传后的类型", this.acceptType)
        },
        /**
         * @Description: 上传功能回调
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-07-25 14:20:43
         * @param {*} res
         * @param {*} file
         * @param {*} fileList
         */
        handleSuccess(res, file, fileList) {
            if (res.code === "200") {
                this.ruleFormDormitory.img.push(res.data.url);
                console.log("图片回调：", this.ruleFormDormitory.img);
                if (this.ruleFormDormitory.img.length >= 3) {
                    this.isUpdataImg = false;
                }
                if (this.ruleFormDormitory.img.length>= 1 && this.acceptType === "video") {
                    this.isUpdataVideo = false
                }
            }
        },
        /**
         * @Description: 添加考核行为
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-07-25 14:20:59
         */
        handleSave() {
            this.$refs.formRef.validate((val) => {
                if (val) {
                    let form = JSON.parse(JSON.stringify(this.ruleFormDormitory));
                    form.img = form.img.toString();
                    form.behaviorId = this.ruleFormDormitory.behaviorId;
                    form.ruleId = this.ruleFormDormitory.ruleId;
                    let formObj = {
                        ...this.dormitoryForm,
                        ...form,
                        ...this.stuId
                    };
                    const sManagerEduDeormitoryDataModule =
                        new SManagerEduDeormitoryDataModule();
                    sManagerEduDeormitoryDataModule
                        .updateDormitoryRoomDetailInfo(formObj)
                        .then((res) => {
                            if (res.data.code === "200") {
                                this.$message.success("编辑考核成功");
                                this.handleCloseDormitory();
                                this.$emit('recordPopup',this.updatObj)
                                this.$emit('getList');
                            } else if (res.data && res.data.msg) {
                                this.$message.error(res.data.msg);

                            }
                        })
                        .catch(() => {});
                }
            });
        },
        /**
         * @Description: 关闭添加考核行为弹窗
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 闫乔
         * @Date: 2023-07-25 14:21:09
         */
        handleCloseDormitory() {
            this.isUpdataVideo = true
            this.isUpdataImg = true
            this.dialogObjDormitory.dialogVisible = false;
            this.ruleFormDormitory =
                this.$options.data.call().ruleFormDormitory;
            this.behaviorList = [];
            this.$nextTick(() => {
                this.$refs.formRef.clearValidate();
            });
            // this.$emit('recordPopup',this.updatObj)
        },
        // 德育考核相关
        getEduMoralRuleList(data,itemData) {
            this._fet("/school/schoolMoralRule/listByCondition", {
                schoolId: this.schoolId,
            }).then((res) => {
                if (res.data.code === "200") {
                    this.ruleListEduMoral = formatTreeData(
                        res.data.data,
                        "id",
                        "parentId",
                    );
                    this.ruleFormEduMoral.ruleId = (res.data.data.find((i) => i.id === data))?(res.data.data.find((i) => i.id === data).id):"";
                    this.$nextTick(() => {
                            this.$refs.formRefEduMoral.clearValidate();
                        });
                    this.changeRuleEduMoral(data,itemData)
                }
            });
        },
        /**
         * @Description: 选择考核指标，获取考核行为数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author:
         * @Date:
         */
        changeRuleEduMoral(data,itemData) {
            this.behaviorListEduMoral = [];
            this.ruleFormEduMoral.ruleType = "";
            this.ruleFormEduMoral.behaviorId = "";
            this.ruleFormEduMoral.score = "";
            const current = treeFind(this.ruleListEduMoral, (x) => x.id === data);
            if (current) {
                this.ruleFormEduMoral.ruleType = current.ruleType;
                this.behaviorListEduMoral = current.behaviorList;
                this.ruleFormEduMoral.behaviorId = (this.behaviorListEduMoral.find((i) => i.id === itemData))?(this.behaviorListEduMoral.find((i) => i.id === itemData).id):'';
                this.ruleFormEduMoral.score = (this.behaviorListEduMoral.find((i) => i.id === itemData))?(this.behaviorListEduMoral.find((i) => i.id === itemData).score):"";
            }
        },
        changeBehaviorEduMoral(data) {
            let find = this.behaviorListEduMoral.find((i) => i.id === data);
            if (find) {
                this.ruleFormEduMoral.score = find.score;
            } else {
                this.ruleFormEduMoral.score = "";
            }
        },
        /**
         * @Description: 上传校验
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author:
         * @Date:
         */
        beforeUploadEduMoral(file) {
            console.log("file上传文件的类型", file)
            if(this.ruleFormEduMoral.img.length >=1 && this.acceptTypes ==='image'){
                console.log('进来了吗');
                if (file.type === "video/mp4") {
                    this.$message.error("只允许上传图片!");
                    this.acceptTypes ==='image'
                    return false;
                }
            }
            //如果上传的是图片格式-限制上传3张
            const isImage = file.type.split("/")[0] === "image";
            const isLt20M = file.size / 1024 / 1024 < 20;
            const is50 = file.name.length < 50;
            //如果上传的是视频格式-限制上传1个视频大小在100M以内
            const isVideo = file.type === "video/mp4";
            const isLt100M = file.size / 1024 / 1024 < 100;
            if (isImage) {
                this.acceptTypes = "image"
                this.acceptValues = "image/*"
                if (!is50) {
                    this.$message.error("上传图片名称不能超过50个字符!");
                    return false;
                }
                if (!isLt20M) {
                    this.$message.error("上传图片大小不能超过 20MB!");
                    return false;
                }
            } else if (isVideo) {
                this.acceptTypes = "video"
                this.acceptValues = "video/mp4"
                if (!isLt100M) {
                    this.$message.error("视频超过系统限制请编辑后上传");
                    this.acceptValues = "image/*,video/mp4"
                    return false;
                }
               // 校验上传视频的时长不能超过20S
                return new Promise((resolve, reject) => {
                let video = document.createElement('video');
                video.preload = 'metadata';
                let _this = this;
                video.onloadedmetadata = function() {
                    window.URL.revokeObjectURL(video.src);
                    let duration = video.duration;
                    //alert('视频时长为：' + duration + '秒');
                    if(duration > 20) {
                        _this.$message.error('视频超过系统限制请编辑后上传');
                        _this.acceptValues = "image/*,video/mp4"
                    reject('视频超过系统限制请编辑后上传');
                    } else {
                    resolve(true);
                    }
                }
                video.onerror = function() {
                    reject('无法获取视频时长');
                }
                video.src = URL.createObjectURL(file);
                });
            } else if(this.ruleFormEduMoral.img.length >=1) {
                this.acceptTypes = "image"
                this.acceptValues = "image/*"
                this.$message.error("只允许上传图片!");
                return false;
            }else {
                this.acceptTypes = ""
                this.acceptValues = "image/*,video/mp4"
                this.$message.error("只允许上传图片 或 视频!");
                return false;
            }
           console.log("上传后的类型", this.acceptTypes)
        },
        /**
         * @Description: 上传功能回调
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author:
         * @Date:
         */
        handleSuccessEduMoral(res, file, fileList) {
            if (res.code === "200") {
                this.ruleFormEduMoral.img.push(res.data.url);
                if (this.ruleFormEduMoral.img.length >= 3) {
                    this.isUpdataImgs = false
                }
                if (this.ruleFormEduMoral.img.length>= 1 && this.acceptTypes === "video") {
                    this.isUpdataVideos = false
                }
            }
        },
        // 删除某一张图片
        deleteSwiperImgEduMoral(index) {
            this.fileList1.splice(index, 1)
            this.ruleFormEduMoral.img.splice(index, 1)
            console.log("打印删除后的list",this.ruleFormEduMoral.img.length, this.acceptTypes)
            //如果第一个上传的是图片 后面只能上传图片 或2张图
            if (this.ruleFormEduMoral.img.length > 1 || (this.ruleFormEduMoral.img.length === 1 &&  this.acceptTypes === 'image')) {
                this.acceptTypes = "image"
                this.acceptValues = "image/*"
            } else {
                this.acceptValues = "image/*,video/mp4"
                this.acceptTypes = ""
            }
            //如果 length === 0 显示上传按钮
            if (this.ruleFormEduMoral.img.length === 0){
                this.isUpdataImgs = true
                this.isUpdataVideos = true
            }else if(this.ruleFormEduMoral.img.length < 3){
                this.isUpdataImgs = true
            }
            console.log(this.isUpdataImgs,this.isUpdataVideos,'是否可以上传图片');
        },
        /**
         * @Description: 添加考核行为
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author:
         * @Date:
         */
        handleSaveEduMoral() {
            if (
                !this.ruleFormEduMoral.gradeId ||
                !this.ruleFormEduMoral.classId
            ) {
                this.$message.warning(
                    "该学生缺少年级班级信息，请补充后再编辑考核",
                );
                return;
            }
            this.$refs.formRefEduMoral.validate((val) => {
                if (val) {
                    let formEdu = JSON.parse(JSON.stringify(this.ruleFormEduMoral));
                    formEdu.img = formEdu.img.toString();
                    console.log('formEdu.ruleId',formEdu.ruleId);
                    let formEduObj ={
                        ...formEdu,
                        ...this.stuId
                    }
                    this._fet(
                        "/school/schoolMoralStudentDetail/updateStudentMoral",
                        formEduObj,
                    )
                        .then((res) => {
                            if (res.data.code === "200") {
                                this.$message.success("编辑考核成功");
                                this.handleCloseEduMoral();
                                this.$emit('recordPopup',this.updatObj)
                            } else if (res.data && res.data.msg) {
                                this.$message.error(res.data.msg);
                            }
                        })
                        .catch(() => {});
                }
            });
        },
        /**
         * @Description: 关闭添加考核行为弹窗
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author:
         * @Date:
         */
        handleCloseEduMoral() {
            this.isUpdataImgs = true
            this.isUpdataVideos = true
            this.dialogObjEduMoral.dialogVisible = false;
            this.ruleFormEduMoral = this.$options.data.call().ruleFormEduMoral;
            this.behaviorListEduMoral = [];
            this.$nextTick(() => {
                this.$refs.formRefEduMoral.clearValidate();
            });
            // this.$emit('recordPopup',this.updatObj)
        },
        deleteProcessing(row, item) {
            this.$confirm("确定要删除这条记录吗？", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.$emit("deleteProcessing", {row: row, item: item});
                })
                .catch(() => {});
        },
    },
};
</script>
<style lang="scss" scoped>
.moral-education-assessment-records {
    .top_row {
        border: 1px solid #ebeef5;
        border-bottom: none;
        height: 40px;
        display: flex;
        // align-items: center;
        padding-left: 10px;
        line-height: 40px;

        .score_img {
            margin-top: 2px;
        }

        .all_score {
            margin-left: 7px;
        }

        .add_score {
            color: #f99d5e;
            margin-left: 4px;
        }

        .reduce_score {
            color: #71ce66;
            margin-left: 4px;
        }
    }
    .img2 {
        width: 60px;
        height: 45px;
        border-radius: 8px;
        cursor: pointer;
    }
    .add {
        color: #f99d5e;
    }
    .jian {
        color: #71ce66;
    }
    .btn_option {
        color: #3C7FFF;
        cursor: pointer;
    }
    .btn_delet {
        color: #e63a2e;
        cursor: pointer;
    }
    .processing-list {
        background-color: #fcfcfc;
        box-sizing: border-box;
        padding: 11px 20px 17px 24px;
        border-bottom: 1px solid #edf0f2;
        .processing-title {
            display: flex;
            justify-content: space-between;
            .processing-des {
                width: 700px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #333333;
            }
            .processing-del {
                font-size: 14px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #f24949;
                cursor: pointer;
            }
        }
        .processing-info {
            margin-top: 10px;
        }
    }

    .processing-warper .processing-list:last-of-type {
        border: none;
    }
    .empty {
        box-sizing: border-box;
        padding: 0 10px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #808487;
    }

    .results {
        color: #3C7FFF;
        cursor: pointer;
    }
    .no-results {
        color: #808487;
    }
}
.swiper-wrap {
    display: flex;
    flex-wrap: wrap;
}

.img_each {
    width: 80px;
    height: 80px;
    overflow: hidden;
    box-sizing: border-box;
    position: relative;
    border-radius: 8px;
    border: 1px solid #c0ccda;
    margin-right: 10px;

    img {
        object-fit: cover;
    }

    .del {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: 0;
        background-color: rgba(0, 0, 0, 0.4);
        width: 100%;
        height: 100%;
        opacity: 0;

        &:hover {
            opacity: 1;
        }

        .el-icon-delete {
            font-size: 20px;
            color: #ffffff;
        }
    }
}
.upload-wrap {
    display: flex;
    align-items: center;
}

.upload-btn {
    text-align: center;
    width: 80px;
    height: 80px;
    border: 1px solid #e6e6e6;
    box-sizing: border-box;
    border-radius: 8px;
    margin-right: 16px;

    .add-icon {
        margin-top: 24px;
        font-size: 20px;
        line-height: 20px;
        color: #c2c8cc;
    }

    .upload-text {
        margin-top: 15px;
        line-height: 12px;
        font-size: 12px;
        color: #999999;
    }
}
.empty-text {
    text-align: center;
    margin-top: 70px;
}
// 图片数量等于限制数量时隐藏上传组件
::v-deep.hide {
    .el-upload--avatar-uploader {
        display: none !important;
    }
}
.simple-dialog-form {
    .footer-button-group {
        text-align: right;
    }

    .behavior-select {
        ::v-deep .el-select-dropdown__item{
            width: 452px;
        }
    }
}
.tipText{
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 12px;
    color: #BBBDBF;
}
.dialog-form-video{
    ::v-deep .el-dialog {
        width: 700px !important;
        height: 600px!important;
    }
}
</style>
